import React, { useEffect, useState, useMemo } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import SEO from '../components/SEO'
import { useQueryParam, StringParam } from 'use-query-params'
import RegisterForm from '../components/Register/RegisterForm'
import RegisterFields from '../components/Register/RegisterFields'
import Login from '../components/Login/Login'
import RadioCondition from '../components/Login/RadioCondition'
import { useUserContext } from '../contexts/User/userContext'
import { useEmployerContext } from '../contexts/Employer/employerProvider'
import ConditionProvider from '../components/common/ConditionProvider'
import EmployerIdentificationLayer from '../components/LayerComponents/EmployerIdentificationLayer'
import LoginPrompt from '../components/common/LoginPrompt'
import { useLocalizedContent } from '../utils/localization/useLocalizedContent'

const SignupLoginComponent = ({ location }) => {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const signup = location.pathname.includes('signup')
  const [redirectUrl] = useQueryParam('r', StringParam)
  const [loading, setLoading] = useState(false)
  const [freshAccount, setFreshAccount] = useState(false)
  const [eligibleConditions, setEligibleConditions] = useState([])
  const [condition, setCondition] = useState('')

  const { employer } = useEmployerContext()

  const eligibilityType = useMemo(
    () => employer?.eligibilityCheckType?.toLowerCase(),
    [employer]
  )
  const enrollmentTypeSelector = useMemo(
    () => employer?.showEnrollmentTypeSelector,
    [employer]
  )
  const showDivisions = useMemo(() => employer?.showDivisions, [employer])
  const divisions = useMemo(() => employer?.divisions, [employer])

  const isExcludedFromEligibilityCheck = eligibilityType === 'no check'

  const { user } = useUserContext()

  console.log(
    `SignupLogin render loading=${loading} freshAccount=${freshAccount} user.isAuth=${user?.isAuth} employer=${employer?.slug}`
  )

  useEffect(() => {
    console.log(
      `SignupLogin useEffect loading=${loading} freshAccount=${freshAccount} user.isAuth=${user?.isAuth}`
    )
    // If there is an authenticated user, take them to their logged in home
    // Make sure we only navigate if there's no redirect url...
    if (!loading & !freshAccount && user?.isAuth && !redirectUrl) {
      console.log(`SignupLogin logged in user detected, navigating to /`)
      // noinspection JSIgnoredPromiseFromCall
      if (isExcludedFromEligibilityCheck) {
        if (condition) {
          return navigate(`/quiz/${condition}`)
        }
        return
      }
      navigate('/')
    } else {
      setFreshAccount(true)
    }
  }, [
    condition,
    freshAccount,
    isExcludedFromEligibilityCheck,
    loading,
    redirectUrl,
    user?.isAuth,
    user.state
  ])

  // Add the URL parameters to the URL to have the user be associated with the employer if there's an employer cookie
  useEffect(() => {
    if (signup && employer) {
      setEligibleConditions(
        employer?.eligibleConditions?.map((c) => c.slug) || []
      )
    }
  }, [signup, employer])

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <SEO title="" description="" location={location} />

      {signup ? (
        <EmployerIdentificationLayer>
          <div className="mx-auto">
            <div className="text-2xl font-medium leading-snug text-center text-gp-grey-100 md:text-3xl">
              <p>{translations[translationsKeys.title]}</p>
            </div>

            <p className="mt-1 text-base leading-snug text-center md:text-lg text-gp-grey-100">
              {translations[translationsKeys.subtitle]}
            </p>

            <div className="flex justify-center max-w-lg pb-8 mx-auto mt-8 md:mt-12 md:max-w-xl md:px-6">
              <div className="w-full">
                <RegisterForm
                  requireEligible={!isExcludedFromEligibilityCheck}
                  isExcludedFromEligibilityCheck={
                    isExcludedFromEligibilityCheck
                  }
                  showDivisions={showDivisions}
                  enrollmentTypeSelector={enrollmentTypeSelector}
                  location={location}
                  onLoading={setLoading}
                  onSelectCondition={setCondition}
                >
                  <RadioCondition
                    eligibleConditions={eligibleConditions}
                    onChange={(e) => setCondition(e.target.value)}
                  />

                  <RegisterFields
                    enrollmentTypeSelector={enrollmentTypeSelector}
                    checkEligibility={!isExcludedFromEligibilityCheck}
                    divisions={divisions}
                  />
                </RegisterForm>
                <div className="mt-6 text-center">
                  <LoginPrompt />
                  <p className="pt-1 leading-tight text-md">
                    <a
                      href="https://www.goodpath.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {translations[translationsKeys.privacyLinkText]}
                    </a>
                  </p>
                  <p className="pt-1 leading-tight text-md">
                    <a
                      href="https://www.goodpath.com/notice-of-privacy-practices"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {translations[translationsKeys.privacyPractices] || 'Notice of Privacy Practices'}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </EmployerIdentificationLayer>
      ) : (
        <Login location={location} />
      )}
    </div>
  )
}

const query = graphql`
  query SignupLoginQuery {
    allContentfulContentGroup(filter: { readableId: { eq: "signup-login" } }) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`

const translationsKeys = {
  title: 'title',
  subtitle: 'subtitle',
  privacyLinkText: 'privacyLinkText',
  benefitCheckboxLabel: 'benefitCheckboxLabel',
  privacyPractices: 'privacyPractices'
}

const SignupLogin = (props) => (
  <ConditionProvider>
    <SignupLoginComponent {...props} />
  </ConditionProvider>
)

export default SignupLogin
