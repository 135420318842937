import React from 'react'
import classNames from 'classnames'
import { Field } from 'react-final-form'

const Divisions = ({ divisions, label, placeholder }) => {
  return (
    <Field name="division">
      {({ input, meta }) => {
        const error = (meta.error || meta.submitError) && meta.touched

        const labelClass = classNames(
          'inline-block mb-2 text-base font-bold text-medium-grey',
          {
            'text-ui-error': error
          }
        )

        const selectClass = classNames(
          'block w-full py-3 pl-4 pr-8 text-base leading-snug text-black truncate bg-white border rounded appearance-none form-select focus:outline-none focus:shadow-outline-gp-violet focus:border-gp-violet border-grey-md hover:border-grey-base',
          {
            'border-ui-error hover:border-ui-error': error
          }
        )

        return (
          <>
            <label htmlFor="division" className={labelClass}>
              {label || "Company/Division"}
            </label>
            <select
              {...input}
              id="division"
              name="division"
              className={selectClass}
              defaultValue={placeholder || "Please select your company/division"}
            >
              <option disabled value="">
                {placeholder || "Please select your company/division"}
              </option>
              {divisions.map((item) => (
                <option key={item.value} value={item?.value}>{item?.label}</option>
              ))}
            </select>
            {error && (
              <div className="mt-2 text-sm text-ui-error">
                {meta.error || meta.submitError}
              </div>
            )}
          </>
        )
      }}
    </Field>
  )
}

export default Divisions
