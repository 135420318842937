import React from 'react'
import { useFormState } from 'react-final-form'
import Input from '../Input'
import { Context } from './RegisterForm'
import Divisions from './Divisions'
import EmailEligibilityInput from './EmailEligibilityInput'
import api from '../../api'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import EnrollmentTypeSelector from './EnrollmentTypeSelector'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'


export default function RegisterFields({
  checkEligibility,
  enrollmentTypeSelector,
  divisions,
}) {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const fieldValues = useFormState()
  const { setEmployerSlug } = useEmployerContext()

  return (<Context.Consumer>
    {(value) => {
      const { setEligibilityStatus } = value
      return (
        <>
          {divisions && (
            <div className="relative mt-6">
              <Divisions
                divisions={divisions}
                label={translations[translationsKeys.divisionLabel]}
                placeholder={translations[translationsKeys.divisionPlaceholder]}
              />
            </div>
          )}
          {checkEligibility && (
            <div className="relative flex flex-col mt-6">
              <EmailEligibilityInput
                employerService={api.employer}
                type={fieldValues.values.enrollmentType}
                onEmployerSet={(slug) => setEmployerSlug(slug)}
                onEligibilityStatusChange={setEligibilityStatus}
              />
            </div>
          )}
          {enrollmentTypeSelector && (
            <EnrollmentTypeSelector
              defaultValue='employee'
              employeeLabel={translations[translationsKeys.enrollmentTypeLabelEmployee]}
              dependentLabel={translations[translationsKeys.enrollmentTypeLabelDependent]}
            />
          )}

          <div className="mt-6">
            <Input
              type="email"
              label={translations[translationsKeys.emailLabel] || 'Create your Goodpath account'}
              name="email"
              placeholder={translations[translationsKeys.emailPlaceholder] || 'Personal email recommended'}
            />
          </div>
          <div className="flex mt-6 space-x-4">
            <div className="flex-1">
              <Input
                type="text"
                label={translations[translationsKeys.firstNameLabel] || 'First Name'}
                name="firstName"
                placeholder={translations[translationsKeys.firstNamePlaceholder] || 'First name'}
              />
            </div>
            <div className="flex-1">
              <Input
                type="text"
                label={translations[translationsKeys.lastNameLabel] || 'Last Name'}
                name="lastName"
                placeholder={translations[translationsKeys.lastNamePlaceholder] || 'Last name'}
              />
            </div>
          </div>
          <div className="mt-6">
            <Input
              type="password"
              label={translations[translationsKeys.passwordLabel] || 'Password'}
              name="password"
              placeholder={translations[translationsKeys.passwordLabel] || 'Password'}
              passwordField={true}
              passwordStrength={true}
            />
          </div>
        </>
      )
    }}
  </Context.Consumer>
  )
}

const query = graphql`
  query RegisterFieldsQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "register-fields"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`

const translationsKeys = {
  emailLabel: 'emailLabel',
  emailPlaceholder: 'emailPlaceholder',
  firstNameLabel: 'firstNameLabel',
  firstNamePlaceholder: 'firstNamePlaceholder',
  lastNameLabel: 'lastNameLabel',
  lastNamePlaceholder: 'lastNamePlaceholder',
  passwordLabel: 'passwordLabel',
  divisionLabel: 'divisionLabel',
  divisionPlaceholder: 'divisionPlaceholder',
  enrollmentTypeLabelEmployee: 'enrollmentTypeLabelEmployee',
  enrollmentTypeLabelDependent: 'enrollmentTypeLabelDependent',
}