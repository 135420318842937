import { useCookies } from 'react-cookie'

const samlCookieName = '___su'

const useSamlCookie = () => {
  const [cookie] = useCookies([samlCookieName])
  const cookieValue = cookie[samlCookieName]

  if (!cookieValue) {
    console.info('SAML cookie not found')
  }

  return cookieValue
}

export default useSamlCookie
