import { useCookies } from 'react-cookie'

const useUtmCookie = () => {
  const [cookie] = useCookies([
    'ft_referrer',
    'ft_utm_source',
    'ft_utm_medium',
    'ft_utm_campaign',
    'ft_utm_term',
    'ft_utm_content',
    'lt_referrer',
    'lt_utm_source',
    'lt_utm_medium',
    'lt_utm_campaign',
    'lt_utm_term',
    'lt_utm_content'
  ])

  return cookie
}

export default useUtmCookie
