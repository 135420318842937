import React from 'react'
import { Field } from 'react-final-form'
import classNames from 'classnames'
import ConditionProvider, {
  useConditionContext
} from '../common/ConditionProvider'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

function RadioConditionComponent({
  eligibleConditions = [],
  ...props
}) {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const { conditions } = useConditionContext()
  return (
    <>
      <Field name="condition" {...props}>
        {({ input, meta }) => {
          const error = (meta.error || meta.submitError) && meta.touched

          const labelClass = classNames(
            'mb-2 inline-block font-bold text-medium-grey text-base',
            {
              'text-ui-error': error
            }
          )

          const selectClass = classNames(
            'form-select block w-full py-3 px-4 text-base focus:outline-none focus:shadow-outline-gp-violet focus:border-gp-violet leading-snug border border-grey-md rounded appearance-none bg-white hover:border-grey-base text-black',
            {
              'border-ui-error hover:border-ui-error': error
            }
          )

          return (
            <>
              <label htmlFor="condition" className={labelClass}>
                {translations[translationsKeys.conditionRadioLabel] || 'Condition'}
              </label>
              <select
                {...input}
                id="condition"
                className={selectClass}
                onChange={(e) => {
                  props.onChange && props.onChange(e)
                  input.onChange(e)
                }}
              >
                <option disabled value="">
                  {translations[translationsKeys.conditionRadioPlaceholder] || 'Please select a condition'}
                </option>
                {conditions
                  .filter((rc) => {
                    return eligibleConditions?.length > 0
                      ? eligibleConditions.includes(rc.slug)
                      : true
                  })
                  .map((option) => {
                    return (
                      <option key={option.slug} value={option.slug}>
                        {option.fullTitle}
                      </option>
                    )
                  })}
              </select>
              {!props.hideError && error && (
                <div className="mt-2 text-sm text-ui-error">
                  {meta.error || meta.submitError}
                </div>
              )}
            </>
          )
        }}
      </Field>
    </>
  )
}

const RadioCondition = (props) => {
  return (
    <ConditionProvider>
      <RadioConditionComponent {...props} />
    </ConditionProvider>
  )
}

const query = graphql`
  query RadioConditionQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "condition-radio"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`

const translationsKeys = {
  conditionRadioLabel: 'conditionRadioLabel',
  conditionRadioPlaceholder: 'conditionRadioPlaceholder'
}

export default RadioCondition
