import React, { useEffect, useState } from 'react'
import { Field, FieldInputProps, FieldRenderProps } from 'react-final-form'

export default function EnrollmentTypeSelector({
  defaultValue,
  onChange,
  employeeLabel,
  dependentLabel
}: Props) {
  const [_value, setValue] = useState(defaultValue)

  useEffect(() => {
    onChange?.(_value)
  }, [_value, onChange])

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    input: FieldInputProps<string, HTMLElement>
  ) => {
    setValue(e.target.value as EnrollmentType)
    input.onChange(e)
  }

  return (
    <div className="flex items-center justify-around pt-8 pb-2">
      <div className="flex items-center">
        <label className="cursor-pointer">
          <Field
            type="radio"
            name="enrollmentType"
            value="employee"
            component="input"
            className="w-5 h-5 cursor-pointer form-radio"
          >
            {(props) => {
              return (
                <Radio
                  {...props}
                  title={employeeLabel || 'I am an employee'}
                  handleOnChange={handleOnChange}
                  checked={_value === 'employee' || props.input.checked}
                />
              )
            }}
          </Field>
        </label>
      </div>
      <div className="flex items-center">
        <label className="cursor-pointer">
          <Field
            type="radio"
            name="enrollmentType"
            value="dependent"
            component="input"
            className="w-5 h-5 cursor-pointer form-radio"
          >
            {(props) => {
              return (
                <Radio
                  {...props}
                  title={dependentLabel || 'I am a dependent'}
                  handleOnChange={handleOnChange}
                  showError={false}
                  checked={_value === 'dependent' || props.input.checked}
                />
              )
            }}
          </Field>
        </label>
      </div>
    </div>
  )
}

const Radio = ({
  showError = true,
  ...props
}: FieldRenderProps<string, HTMLElement, string>) => {
  const { input, meta, className } = props
  const error = (meta.error || meta.submitError) && meta.touched
  return (
    <div className="flex flex-col">
      <div>
        <input
          {...input}
          className={className}
          onChange={(e) => props.handleOnChange(e, input)}
          checked={props.checked}
        />
        <span className="px-2 text-sm md:text-base">{props.title}</span>
      </div>
      {showError && error && (
        <div className="mt-2 text-sm text-ui-error">
          {meta.error || meta.submitError}
        </div>
      )}
    </div>
  )
}

type Props = {
  defaultValue: EnrollmentType
  onChange: (value: 'employee' | 'dependent') => void
  employeeLabel?: string
  dependentLabel?: string
}

export type EnrollmentType = 'employee' | 'dependent'
