import React, { useEffect, useMemo, useState } from 'react'
import Input from '../Input'
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle'
import { CloseCircleOutline } from '@styled-icons/evaicons-outline/CloseCircleOutline'
import Spinner from '../common/Spinner'
import IsEmail from 'validator/lib/isEmail'
import { useDebounce } from '../../utils/useDebounce'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

export enum EligibilityStatuses {
  notSet = 'notSet',
  loading = 'loading',
  success = 'success',
  fail = 'fail'
}

export default function EmailEligibilityInput({
  employeeId,
  employerService,
  type,
  conditionSlug,
  onEmployerSet,
  onEligibilityStatusChange,
  onValueChange
}: Props) {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent<any, Translations>(data)

  const [email, setEmail] = useState('')
  const [eligibilityStatus, setEligibilityStatus] = useState(
    EligibilityStatuses.notSet
  )

  const { slug: employerSlug } = useEmployerContext()

  const error = useMemo(
    () => !(email && email.trim().length !== 0 && IsEmail(email)),
    [email]
  )

  const debounceEligibilityCheck = useDebounce(
    async ({ value }: { value: EligibilityStatuses }) => {
      try {
        if (!value) {
          setEligibilityStatus(EligibilityStatuses.notSet)
          return
        }

        setEligibilityStatus(EligibilityStatuses.loading)
        const res = await employerService.eligible({
          value,
          employerSlug,
          conditionSlug
        })

        const status = res.eligible
          ? EligibilityStatuses.success
          : EligibilityStatuses.fail
        setEligibilityStatus(status)

        if (res?.employer?.slug) {
          onEmployerSet?.(res.employer.slug)
        }
      } catch (error) {
        console.log(error)
        setEligibilityStatus(EligibilityStatuses.fail)
      }
    },
    1000
  )

  useEffect(() => {
    onValueChange?.(email)
  }, [email, onValueChange])

  useEffect(() => {
    if (eligibilityStatus === EligibilityStatuses.loading) return
    onEligibilityStatusChange?.(eligibilityStatus)
  }, [eligibilityStatus, onEligibilityStatusChange])

  useEffect(() => {
    const value = email || employeeId
    if (!value || error) return

    debounceEligibilityCheck({
      value
    })
  }, [debounceEligibilityCheck, email, employeeId, error])

  return (
    <>
      <Input
        type="email"
        label={translations.emailEligibilityLabel || 'See if you are eligible'}
        name="workEmail"
        placeholder={
          type === 'dependent'
            ? translations.emailEligibilityInputPlaceholderDependentLabel ||
              "Family member's work email"
            : translations.emailEligibilityInputPlaceholderEmployeeLabel ||
              'Work email'
        }
        value={email}
        onChange={(value: string) => {
          setEmail(value)
        }}
      />
      <div className="absolute flex items-center self-end justify-end h-full pr-1">
        {!error && (
          <div className="mb-4">
            {eligibilityStatus === EligibilityStatuses.success && (
              <CheckCircle className="absolute right-0 w-10 mr-2 text-green-600" />
            )}
            {eligibilityStatus === EligibilityStatuses.fail && (
              <CloseCircleOutline className="absolute right-0 w-10 mr-2 text-red-600" />
            )}
            {eligibilityStatus === EligibilityStatuses.loading && (
              <div className="absolute right-0 w-10 mr-2 ">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

type Props = {
  employeeId?: string
  conditionSlug?: string
  employerService: any
  type: 'dependent' | 'employee'
  onEmployerSet?: (employerSlug: string) => void
  onValueChange?: (value: string) => void
  onEligibilityStatusChange?: (status: EligibilityStatuses) => void
}

interface Translations {
  emailEligibilityLabel: string
  emailEligibilityInputPlaceholderDependentLabel: string
  emailEligibilityInputPlaceholderEmployeeLabel: string
}

const query = graphql`
  query EmailEligibilityInputQuery {
    allContentfulContentGroup(
      filter: { readableId: { eq: "email-eligibility-input" } }
    ) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`
